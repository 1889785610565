import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Box,
    Typography,
    Autocomplete,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Alert,
    AlertTitle,
    Snackbar,
    AppBar,
    Toolbar,
    IconButton,
    CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { renderToString } from 'react-dom/server';
import { Link } from 'react-router-dom';
import { api } from '../api';
import dompurify from 'dompurify';
import html2canvas from 'html2canvas';

import writeXlsxFile from 'write-excel-file';
import jsPDF from 'jspdf';

export default function Admin({ checkSignedIn }) {
    const [parties, setParties] = useState([]);
    const [guestCount, setGuestCount] = useState(0);
    const [attendingCount, setAttendingCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getParties();
    }, []);

    const signOut = () => {
        localStorage.removeItem('token');
        checkSignedIn();
    };

    const saveExcel = async () => {
        const data = [];
        parties.map((party, index) => {
            data.push([{ value: party.name, fontWeight: 'bold' }]);
            data.push([
                {
                    value: 'Name',
                },
                {
                    value: 'Attending?',
                },
                {
                    value: 'Dietary Requirements',
                },
            ]);
            party.Guests.map((guest, index) => {
                data.push([
                    {
                        value: guest.name,
                    },
                    {
                        value: guest.isAttending
                            ? 'Yes'
                            : guest.isAttending === false
                            ? 'No'
                            : 'Not yet responded',
                    },
                    {
                        value: guest.dietaryRequirements || 'None',
                    },
                ]);
            });
        });
        await writeXlsxFile(data, {
            fileName: `kt-guest-list-${Date.now()}.xlsx`,
        });
    };

    const generatePDF = () => {
        const doc = new jsPDF();
        let cursorY = 17;
        const newLine = () => {
            const pageHeight = doc.internal.pageSize.height;
            if (cursorY > pageHeight - 50) {
                // Auto-paging
                doc.addPage();
                cursorY = 17;
            }
            cursorY += 7;
        };
        parties.map((party, index) => {
            doc.setFontSize(22);
            doc.text(20, cursorY, party.name);
            newLine();
            newLine();
            // doc.
            doc.setFontSize(16);
            party.Guests.map((guest, index) => {
                const pageHeight = doc.internal.pageSize.height;
                if (cursorY + 21 > pageHeight - 50) {
                    doc.addPage();
                    cursorY = 17;
                }
                doc.text(20, cursorY, guest.name);
                newLine();

                doc.text(
                    20,
                    cursorY,
                    `Attending?: ${
                        guest.isAttending
                            ? 'Yes'
                            : guest.isAttending === false
                            ? 'No'
                            : 'Not yet responded'
                    }`
                );
                newLine();

                doc.text(
                    20,
                    cursorY,
                    `Dietary Requirements: ${
                        guest.dietaryRequirements || 'None'
                    }`
                );

                newLine();
                newLine();
            });

            if (index < parties.length - 1) {
                doc.addPage();
                cursorY = 17;
            }
        });
        doc.save(`kt-guest-list-${Date.now()}.pdf`);
        // doc.html(string, {
        //     callback: () => {
        //         doc.save(`kt-guest-list-${Date.now()}.pdf`);
        //     },
        //     dompurify,
        //     html2canvas,
        // });
    };

    const getParties = async () => {
        const resp = await api.get('/parties', {
            headers: {
                Authorization: localStorage.getItem('token'),
            },
        });

        setParties(resp.data.parties);

        setGuestCount(
            resp.data.parties.reduce((acc, curr) => acc + curr.Guests.length, 0)
        );

        setAttendingCount(
            resp.data.parties.reduce(
                (acc, curr) =>
                    acc +
                    curr.Guests.filter((guest) => guest.isAttending).length,
                0
            )
        );

        setIsLoading(false);
    };

    let content;

    if (isLoading) {
        content = () => <CircularProgress />;
    } else {
        content = () => (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '50px',
                    backgroundColor: 'white',
                    borderRadius: '20px',
                    boxShadow: 4,
                    margin: '15px',
                    minWidth: { sm: '700px', xs: '90vw' },
                }}
            >
                <Link to="/admin/">Go to Edit Guest List</Link>

                <Button
                    variant="contained"
                    onClick={generatePDF}
                    fullWidth
                    sx={{
                        textTransform: 'none',
                        boxShadow: 'none',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'RockfordSans-Light',
                    }}
                >
                    Save as PDF
                </Button>

                <Button
                    variant="contained"
                    onClick={saveExcel}
                    fullWidth
                    sx={{
                        textTransform: 'none',
                        boxShadow: 'none',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'RockfordSans-Light',
                    }}
                >
                    Save as Excel
                </Button>
                <Typography
                    sx={{
                        color: '#484848',
                        fontSize: '15px',
                        textAlign: 'center',
                        margin: 'auto',
                    }}
                >
                    Total number of guests: {guestCount}
                </Typography>
                <Typography
                    sx={{
                        color: '#484848',
                        fontSize: '15px',
                        textAlign: 'center',
                        margin: 'auto',
                    }}
                >
                    Total number of guests attending: {attendingCount}
                </Typography>
                <div id="list">
                    {parties.map((party) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                padding: '5px',
                                backgroundColor: 'white',
                                borderRadius: '20px',
                                border: '1px #ccc solid',
                                minWidth: { sm: '600px', xs: '80vw' },
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#484848',
                                    fontSize: '15px',
                                    textAlign: 'center',
                                    margin: 'auto',
                                }}
                            >
                                {party.name}
                            </Typography>
                            {party.Guests.map((guest, index) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                        padding: '10px',
                                        margin: '5px',
                                        backgroundColor: 'white',
                                        borderRadius: '20px',
                                        border: '1px #ccc solid',
                                        minWidth: {
                                            sm: '600px',
                                            xs: '80vw',
                                        },
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#484848',
                                                fontSize: {
                                                    md: '14px',
                                                    xs: '12px',
                                                },
                                            }}
                                        >
                                            {guest.name}
                                        </Typography>
                                    </div>
                                    <Typography
                                        sx={{
                                            color: '#484848',
                                            fontSize: {
                                                md: '14px',
                                                xs: '12px',
                                            },
                                        }}
                                    >
                                        Attending?:{' '}
                                        {guest.isAttending
                                            ? 'Yes'
                                            : guest.isAttending === false
                                            ? 'No'
                                            : 'Not yet responded'}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: '#484848',
                                            fontSize: {
                                                md: '14px',
                                                xs: '12px',
                                            },
                                        }}
                                    >
                                        Dietary Requirements:{' '}
                                        {guest.dietaryRequirements ?? 'None'}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    ))}
                </div>
                <Button
                    type="button"
                    variant="contained"
                    onClick={signOut}
                    fullWidth
                    sx={{
                        textTransform: 'none',
                        boxShadow: 'none',
                        padding: '8px',
                        borderRadius: '8px',
                        fontFamily: 'RockfordSans-Light',
                        backgroundColor: '#c44',
                    }}
                >
                    Logout/登出
                </Button>
            </Box>
        );
    }

    return (
        <>
            <AppBar position="sticky">
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <Link
                        to="/"
                        style={{ textDecoration: 'none', width: '100px' }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                textAlgin: 'center',
                                marginLeft: '5px',
                                marginTop: '5px',
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Hatton-Regular',
                                    fontSize: '48px',
                                    color: '#ccc',
                                    marginLeft: '15px',
                                }}
                            >
                                KT
                            </div>
                            <div
                                style={{
                                    fontFamily: 'BDScript-Regular',
                                    fontSize: '28px',
                                    color: '#eee',
                                    position: 'absolute',
                                    top: '8px',
                                    zIndex: 2,
                                    width: '100%',
                                }}
                            >
                                Kevin &amp; Thoa
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: '25px',
                                    backgroundColor: '#77b3cc',
                                    width: '100%',
                                    height: '14px',
                                }}
                            ></div>
                        </div>
                    </Link>
                </Toolbar>
            </AppBar>
            <div
                className="App"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                {content()}
            </div>
        </>
    );
}
