import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#77b3cc',
            contrastText: '#fff',
        },
        secondary: {
            main: '#dcffff',
        },
        error: {
            main: red.A400,
        },
    },
    components: {
        MuiButton: {
            containedPrimary: {
                color: '#ffffff',
            },
        },
        MuiOutlinedInput: {
            root: {
                notchedOutline: '1000px',
            },
        },
    },
});

export default theme;
