import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Box,
    Typography,
    AppBar,
    Toolbar,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Alert,
    AlertTitle,
    Snackbar,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { api } from '../api';
const confetti = require('canvas-confetti');

export default function Dashboard({ party, token, checkSignedIn, logout }) {
    const [guests, setGuests] = useState(party.Guests);
    const [comingGuests, setComingGuests] = useState([]);
    const [dirty, setDirty] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);
    const [showThankYou, setShowThankYou] = React.useState(false);
    const [showSorry, setShowSorry] = React.useState(false);

    const updateGuests = async (id, attr, value) => {
        setGuests((current) =>
            current.map((guest) => {
                if (guest.id === id) {
                    return { ...guest, [attr]: value };
                }

                return guest;
            })
        );

        setDirty(true);
    };

    const save = async () => {
        setSaving(true);
        const response = await api.post(
            'guest-update-party',
            {
                partyId: party.id,
                guests: guests,
            },
            {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
            }
        );

        if (response.data.okay === true) {
            setOpen(true);
        } else {
            setErrorOpen(true);
        }

        const coming = guests.filter((guest) => guest.isAttending);
        //if respond yes
        if (coming.length > 0) {
            setComingGuests(coming);
            console.log(comingGuests);
            const canvas = document.getElementById('confetti');
            var myConfetti = confetti.create(canvas, {
                resize: true,
                useWorker: true,
            });
            myConfetti({
                particleCount: 100,
                spread: 160,
                // any other options from the global
                // confetti function
            });

            setShowThankYou(true);

            setTimeout(() => setShowThankYou(false), 5000);
        } else {
            setShowSorry(true);

            setTimeout(() => setShowSorry(false), 2000);
        }

        setSaving(false);

        setDirty(false);
    };

    const signOut = () => {
        logout();
        checkSignedIn();
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
        setOpen(false);
    };

    return (
        <>
            {showSorry && (
                <Box
                    sx={{
                        position: 'fixed',
                        left: '50%',
                        margin: 0,
                        textAlign: 'center',
                    }}
                >
                    <p
                        style={{
                            position: 'relative',
                            left: '-50%',
                            top: '20vh',
                            color: 'white',
                            fontSize: '50px',
                        }}
                        class="sorry"
                    >
                        😔 Sorry you couldn't make it!
                    </p>
                </Box>
            )}
            {showThankYou && (
                <>
                    <Box
                        sx={{
                            position: 'fixed',
                            left: '50%',
                            margin: 0,
                            display: { xs: 'none', md: 'inline-block' },
                            textAlign: 'center',
                        }}
                    >
                        <div
                            class="names"
                            style={{
                                position: 'relative',
                                left: '-50%',
                                top: '20vh',
                                color: 'white',
                                fontSize: '30px',
                            }}
                        >
                            <p>
                                {comingGuests
                                    .map((guest) => guest.name.toUpperCase())
                                    .join(', ')
                                    .replace(/,([^,]*)$/, ' AND$1')}
                            </p>
                        </div>
                        <div style={{ position: 'relative', left: '-50%' }}>
                            <div class="heart">❤</div>
                            <div class="text">
                                <p>T</p>
                                <p>H</p>
                                <p>A</p>
                                <p>N</p>
                                <p>K</p>
                                <p>&nbsp;</p>
                                <p>Y</p>
                                <p>0</p>
                                <p>U</p>
                            </div>
                            <br />
                            <div class="you text">
                                <p>&nbsp;</p>
                                <p>F</p>
                                <p>O</p>
                                <p>R</p>
                                <p>&nbsp;</p>
                                <p>C</p>
                                <p>O</p>
                                <p>M</p>
                                <p>I</p>
                                <p>N</p>
                                <p>G</p>
                            </div>
                        </div>
                    </Box>
                    <Box
                        sx={{
                            position: 'fixed',
                            left: '50%',
                            margin: 0,
                            display: { xs: 'inline-block', md: 'none' },
                            textAlign: 'center',
                        }}
                    >
                        <div
                            class="names"
                            style={{
                                position: 'relative',
                                left: '-50%',
                                top: '20vh',
                                color: 'white',
                                fontSize: '18px',
                            }}
                        >
                            <p>
                                {comingGuests
                                    .map((guest) => guest.name.toUpperCase())
                                    .join(', ')
                                    .replace(/,([^,]*)$/, ' AND$1')}
                            </p>
                        </div>
                        <div style={{ position: 'relative', left: '-50%' }}>
                            <div class="heart">❤</div>
                            <div class="text">
                                <p>T</p>
                                <p>H</p>
                                <p>A</p>
                                <p>N</p>
                                <p>K</p>
                            </div>
                            <br />
                            <div class="you text">
                                <p>Y</p>
                                <p>0</p>
                                <p>U</p>
                            </div>

                            <br />
                            <div class="you text">
                                <p>F</p>
                                <p>O</p>
                                <p>R</p>
                            </div>
                            <br />
                            <div class="you text">
                                <p>C</p>
                                <p>O</p>
                                <p>M</p>
                                <p>I</p>
                                <p>N</p>
                                <p>G</p>
                            </div>
                        </div>
                    </Box>
                </>
            )}
            <div
                style={{
                    position: 'fixed',
                    top: '20%',
                    height: '100%',
                    width: '100%',
                }}
            >
                <canvas
                    id="confetti"
                    style={{ height: '100%', width: '100%' }}
                ></canvas>
            </div>
            <AppBar position="sticky">
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <Link
                        to="/"
                        style={{ textDecoration: 'none', width: '100px' }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                textAlgin: 'center',
                                marginLeft: '5px',
                                marginTop: '5px',
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Hatton-Regular',
                                    fontSize: '48px',
                                    color: '#ccc',
                                    marginLeft: '15px',
                                }}
                            >
                                KT
                            </div>
                            <div
                                style={{
                                    fontFamily: 'BDScript-Regular',
                                    fontSize: '28px',
                                    color: '#eee',
                                    position: 'absolute',
                                    top: '8px',
                                    zIndex: 2,
                                    width: '100%',
                                }}
                            >
                                Kevin &amp; Thoa
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: '25px',
                                    backgroundColor: '#77b3cc',
                                    width: '100%',
                                    height: '14px',
                                }}
                            ></div>
                        </div>
                    </Link>
                </Toolbar>
            </AppBar>
            <div
                className="App"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '50px',
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        boxShadow: 4,
                        margin: '15px',
                        minWidth: { sm: '700px', xs: '90vw' },
                    }}
                >
                    <Typography
                        sx={{
                            color: '#484848',
                            fontFamily: 'PlayfairDisplayBold',
                            fontSize: { md: '14px', xs: '12px' },
                        }}
                    >
                        {party.name}
                    </Typography>
                    {dirty && (
                        <Alert severity="warning">
                            <AlertTitle>
                                Changes have not yet been saved.
                                您的更改未保存。
                            </AlertTitle>
                            Please scroll down and click save to confirm your
                            changes. 請向下滾動然後按“保存”。
                        </Alert>
                    )}

                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <Alert
                            onClose={handleClose}
                            severity="success"
                            sx={{ width: '100%' }}
                        >
                            Saved successfully! 保存成功！
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={errorOpen}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <Alert
                            onClose={handleClose}
                            severity="error"
                            sx={{ width: '100%' }}
                        >
                            Could not save. 不能保存。
                        </Alert>
                    </Snackbar>
                    {guests.map((guest) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                padding: '10px',
                                backgroundColor: 'white',
                                borderRadius: '20px',
                                border: '1px #ccc solid',
                                minWidth: { sm: '500px', xs: '60vw' },
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#484848',
                                    fontFamily: 'PlayfairDisplayBold',
                                    fontSize: { md: '14px', xs: '12px' },
                                }}
                            >
                                {guest.name}
                            </Typography>
                            <FormGroup>
                                <FormControl
                                    sx={{
                                        m: 1,
                                        minWidth: 168,
                                        margin: '10px 0',
                                    }}
                                    size="small"
                                >
                                    <InputLabel id="demo-select-small">
                                        Attending?/參加?
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={guest.isAttending}
                                        label="Attending/參加"
                                        onChange={(event) =>
                                            updateGuests(
                                                guest.id,
                                                'isAttending',
                                                event.target.value
                                            )
                                        }
                                    >
                                        <MenuItem value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                        <MenuItem value={null}>
                                            Unknown
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                            <TextField
                                required
                                fullWidth
                                value={guest.dietaryRequirements}
                                multiline
                                rows={2}
                                onChange={(event) =>
                                    updateGuests(
                                        guest.id,
                                        'dietaryRequirements',
                                        event.target.value
                                    )
                                }
                                placeholder="Dietary Requirements/飲食需要"
                                type="text"
                                variant="outlined"
                                InputProps={{
                                    style: {
                                        borderRadius: '7px',
                                        height: '72px',
                                        borderColor: '#555',
                                        fontFamily: 'RockfordSans-Light',
                                    },
                                }}
                            ></TextField>
                        </Box>
                    ))}
                    <Button
                        type="button"
                        variant="contained"
                        onClick={save}
                        disabled={!dirty || saving}
                        fullWidth
                        sx={{
                            textTransform: 'none',
                            boxShadow: 'none',
                            padding: '8px',
                            borderRadius: '8px',
                            fontFamily: 'RockfordSans-Light',
                            backgroundColor: saving ? '#888' : undefined,
                        }}
                    >
                        Save/保存
                    </Button>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={signOut}
                        fullWidth
                        sx={{
                            textTransform: 'none',
                            boxShadow: 'none',
                            padding: '8px',
                            borderRadius: '8px',
                            fontFamily: 'RockfordSans-Light',
                            backgroundColor: '#c44',
                        }}
                    >
                        Logout/登出
                    </Button>
                </Box>
            </div>
        </>
    );
}
