import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';
import { Button, TextField, Box, Typography } from '@mui/material';
import { api } from '../api';

export default function Login({ checkSignedIn }) {
    const [password, setPassword] = useState('');
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {}, []);

    const signIn = async (e) => {
        e?.preventDefault();
        setIsSigningIn(true);

        const authorize = await api.post('/authorize', {
            password,
        });

        setIsSigningIn(false);

        if (authorize.data.authorized) {
            localStorage.setItem('token', authorize.data.token);
            checkSignedIn();
            setIsSignedIn(true);
        }
    };

    if (isSignedIn) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div
            className="App"
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '40px',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '50px',
                    backgroundColor: 'white',
                    borderRadius: '20px',
                    boxShadow: 4,
                    minWidth: { sm: '400px', xs: '90vw' },
                }}
            >
                <Link to="/" style={{ textDecoration: 'none', width: '100%' }}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <div
                            style={{
                                fontFamily: 'Hatton-Regular',
                                fontSize: '48px',
                                color: '#ccc',
                            }}
                        >
                            KT
                        </div>
                        <div
                            style={{
                                fontFamily: 'BDScript-Regular',
                                fontSize: '28px',
                                color: '#888',
                                position: 'absolute',
                                top: '8px',
                                zIndex: 2,
                                width: '100%',
                            }}
                        >
                            Kevin &amp; Thoa
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                zIndex: 1,
                                top: '25px',
                                backgroundColor: 'white',
                                width: '100%',
                                height: '14px',
                            }}
                        ></div>
                    </div>
                </Link>
                <form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        width: '100%',
                    }}
                    onSubmit={signIn}
                >
                    <TextField
                        required
                        fullWidth
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        placeholder="Password/密碼"
                        type="password"
                        variant="outlined"
                        InputProps={{
                            style: {
                                borderRadius: '7px',
                                height: '38px',
                                borderColor: '#555',
                                fontFamily: 'RockfordSans-Light',
                            },
                        }}
                    ></TextField>
                    <Button
                        type="submit"
                        variant="contained"
                        onClick={signIn}
                        fullWidth
                        sx={{
                            textTransform: 'none',
                            boxShadow: 'none',
                            padding: '8px',
                            borderRadius: '8px',
                            fontFamily: 'RockfordSans-Light',
                            backgroundColor: isSigningIn ? '#888' : undefined,
                        }}
                    >
                        {isSigningIn
                            ? 'Singing in.../正在登入'
                            : 'Sign In/登入'}
                    </Button>
                </form>
                {error && (
                    <Typography sx={{ color: 'red' }}>
                        Could not sign in
                    </Typography>
                )}
            </Box>

            <Typography
                sx={{
                    color: '#484848',
                    fontFamily: 'PlayfairDisplay',
                    marginTop: '40px',
                    fontSize: { md: '15px', xs: '12px' },
                    width: { sm: '50vw', xs: '100vw' },
                }}
            >
                <em>
                    The best gift we could ask for is your presence at our
                    wedding. However, if you would like to honour us with a
                    gift, a contribution to our wishing well would be greatly
                    appreciated as we start our new life together.
                </em>
            </Typography>
        </div>
    );
}
