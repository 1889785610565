import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Box,
    Typography,
    Autocomplete,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Alert,
    AlertTitle,
    Snackbar,
    AppBar,
    Toolbar,
    IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { api } from '../api';

export default function Admin({ checkSignedIn }) {
    const [saving, setSaving] = useState(false);
    const [parties, setParties] = useState([]);
    const [partyName, setPartyName] = useState('');
    const [password, setPassword] = useState('');
    const [guestNameInParty, setGuestNameInParty] = useState({});
    const [guests, setGuests] = useState({});
    const [open, setOpen] = React.useState(false);
    const [errorOpen, setErrorOpen] = React.useState(false);

    useEffect(() => {
        getParties();
    }, []);

    const signOut = () => {
        localStorage.removeItem('token');
        checkSignedIn();
    };

    const addParty = async () => {
        await api.post(
            '/create-party',
            {
                name: partyName,
            },
            {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
            }
        );

        await getParties();
    };

    const addGuest = async (partyId) => {
        await api.post(
            '/create-guest',
            {
                name: guestNameInParty[partyId],
                partyId,
            },
            {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
            }
        );

        await getParties();
    };

    const deleteParty = async (partyId) => {
        const response = await api.delete(`/delete-party/${partyId}`, {
            headers: {
                Authorization: localStorage.getItem('token'),
            },
        });

        if (response.data.okay === true) {
            setOpen(true);
        } else {
            setErrorOpen(true);
        }

        await getParties();
    };

    const deleteGuest = async (guestId) => {
        const response = await api.delete(`/delete-guest/${guestId}`, {
            headers: {
                Authorization: localStorage.getItem('token'),
            },
        });

        if (response.data.okay === true) {
            setOpen(true);
        } else {
            setErrorOpen(true);
        }
        await getParties();
    };

    const saveGuest = async (guestId) => {
        const response = await api.put(
            `/update-guest/${guestId}`,
            {
                seatNumber: guests[guestId + 'seat'],
                tableNumber: guests[guestId + 'table'],
                name: guests[guestId + 'name'],
            },
            {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
            }
        );
        if (response.data.okay === true) {
            setOpen(true);
        } else {
            setErrorOpen(true);
        }
    };

    const saveParty = async (partyId) => {
        const response = await api.put(
            `/update-party/${partyId}`,
            {
                ...parties.find((party) => party.id === partyId),
            },
            {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
            }
        );
        if (response.data.okay === true) {
            setOpen(true);
        } else {
            setErrorOpen(true);
        }
    };

    const getParties = async () => {
        const resp = await api.get('/parties', {
            headers: {
                Authorization: localStorage.getItem('token'),
            },
        });

        setParties(resp.data.parties);

        resp.data.parties.map((party) =>
            setGuestNameInParty((current) => ({
                ...current,
                [party.id]: '',
            }))
        );

        resp.data.parties.map((party) =>
            party.Guests.map((guest) => {
                setGuests((current) => ({
                    ...current,
                    [guest.id + 'seat']: guest.seatNumber,
                    [guest.id + 'table']: guest.tableNumber,
                    [guest.id + 'name']: guest.name,
                }));
            })
        );
    };

    const addPassword = async () => {
        if (password.length < 8) {
            setErrorOpen(true);
            return;
        }

        await api.post(
            `/add-password`,
            {
                password,
            },
            {
                headers: {
                    Authorization: localStorage.getItem('token'),
                },
            }
        );

        setOpen(true)
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorOpen(false);
        setOpen(false);
    };


    return (
        <>
            <AppBar position="sticky">
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <Link
                        to="/"
                        style={{ textDecoration: 'none', width: '100px' }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                textAlgin: 'center',
                                marginLeft: '5px',
                                marginTop: '5px',
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Hatton-Regular',
                                    fontSize: '48px',
                                    color: '#ccc',
                                    marginLeft: '15px',
                                }}
                            >
                                KT
                            </div>
                            <div
                                style={{
                                    fontFamily: 'BDScript-Regular',
                                    fontSize: '28px',
                                    color: '#eee',
                                    position: 'absolute',
                                    top: '8px',
                                    zIndex: 2,
                                    width: '100%',
                                }}
                            >
                                Kevin &amp; Thoa
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: '25px',
                                    backgroundColor: '#77b3cc',
                                    width: '100%',
                                    height: '14px',
                                }}
                            ></div>
                        </div>
                    </Link>
                </Toolbar>
            </AppBar>
            <div
                className="App"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '50px',
                        backgroundColor: 'white',
                        borderRadius: '20px',
                        boxShadow: 4,
                        margin: '15px',
                        minWidth: { sm: '700px', xs: '90vw' },
                    }}
                >
                    <Link to="/admin/list">Go to Guest List</Link>
                    {parties.map((party) => (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'flex-start',
                                padding: '10px',
                                backgroundColor: 'white',
                                borderRadius: '20px',
                                border: '1px #ccc solid',
                                minWidth: { sm: '600px', xs: '80vw' },
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#484848',
                                    fontSize: { md: '20px', xs: '24px' },
                                }}
                            >
                                Party: {party.name}
                            </Typography>
                            <div
                                style={{
                                    marginTop: '3px',
                                    marginBottom: '3px',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    sx={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                    value={party.name}
                                    onChange={(event) =>
                                        setParties((items) => [
                                            ...items.slice(
                                                0,
                                                items.findIndex(
                                                    (item) =>
                                                        item.id === party.id
                                                )
                                            ),
                                            {
                                                ...items[
                                                    items.findIndex(
                                                        (item) =>
                                                            item.id === party.id
                                                    )
                                                ],
                                                name: event.target.value,
                                            },
                                            ...items.slice(
                                                items.findIndex(
                                                    (item) =>
                                                        item.id === party.id
                                                ) + 1
                                            ),
                                        ])
                                    }
                                    label="Party Name"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        style: {
                                            borderRadius: '7px',
                                            height: '38px',
                                            borderColor: '#555',
                                            fontFamily: 'RockfordSans-Light',
                                        },
                                    }}
                                ></TextField>
                                <IconButton
                                    type="button"
                                    variant="contained"
                                    onClick={() =>
                                        window.confirm(
                                            `Are you sure you want to delete this party? (${party.name})`
                                        ) && deleteParty(party.id)
                                    }
                                    fullWidth
                                    sx={{
                                        color: '#c44',
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>

                            <Button
                                type="button"
                                variant="contained"
                                onClick={() => saveParty(party.id)}
                                disabled={saving}
                                fullWidth
                                sx={{
                                    textTransform: 'none',
                                    boxShadow: 'none',
                                    padding: '8px',
                                    borderRadius: '8px',
                                    fontFamily: 'RockfordSans-Light',
                                    backgroundColor: saving
                                        ? '#888'
                                        : undefined,
                                }}
                            >
                                Change Party Name
                            </Button>
                            {party.Guests.map((guest, index) => (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        justifyContent: 'flex-start',
                                        padding: '10px',
                                        margin: '5px',
                                        backgroundColor: 'white',
                                        borderRadius: '20px',
                                        border: '1px #ccc solid',
                                        minWidth: { sm: '600px', xs: '80vw' },
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: '3px',
                                            marginBottom: '3px',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: '#484848',
                                                fontSize: {
                                                    md: '14px',
                                                    xs: '12px',
                                                },
                                            }}
                                        >
                                            Guest #{index + 1}
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            sx={{
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                            value={guests[guest.id + 'name']}
                                            onChange={(event) =>
                                                setGuests((current) => ({
                                                    ...current,
                                                    [guest.id + 'name']:
                                                        event.target.value,
                                                }))
                                            }
                                            label="Guest Name"
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{
                                                style: {
                                                    borderRadius: '7px',
                                                    height: '38px',
                                                    borderColor: '#555',
                                                    fontFamily:
                                                        'RockfordSans-Light',
                                                },
                                            }}
                                        ></TextField>

                                        <IconButton
                                            type="button"
                                            variant="contained"
                                            onClick={() =>
                                                window.confirm(
                                                    `Are you sure you want to delete this guest? (${guest.name})`
                                                ) && deleteGuest(guest.id)
                                            }
                                            fullWidth
                                            sx={{
                                                color: '#c44',
                                            }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                    <Typography
                                        sx={{
                                            color: '#484848',
                                            fontSize: {
                                                md: '14px',
                                                xs: '12px',
                                            },
                                        }}
                                    >
                                        Attending?:{' '}
                                        {guest.isAttending
                                            ? 'Yes'
                                            : guest.isAttending === false
                                            ? 'No'
                                            : 'Not yet responded'}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: '#484848',
                                            fontSize: {
                                                md: '14px',
                                                xs: '12px',
                                            },
                                        }}
                                    >
                                        Dietary Requirements:{' '}
                                        {guest.dietaryRequirements ?? 'None'}
                                    </Typography>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        onClick={() => saveGuest(guest.id)}
                                        disabled={saving}
                                        fullWidth
                                        sx={{
                                            textTransform: 'none',
                                            boxShadow: 'none',
                                            padding: '8px',
                                            borderRadius: '8px',
                                            fontFamily: 'RockfordSans-Light',
                                            backgroundColor: saving
                                                ? '#888'
                                                : undefined,
                                        }}
                                    >
                                        Save Guest Details
                                    </Button>
                                </Box>
                            ))}
                            <div
                                style={{
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <TextField
                                    fullWidth
                                    value={guestNameInParty[party.id]}
                                    onChange={(event) =>
                                        setGuestNameInParty((current) => ({
                                            ...current,
                                            [party.id]: event.target.value,
                                        }))
                                    }
                                    placeholder="Guest Name"
                                    variant="outlined"
                                    InputProps={{
                                        style: {
                                            borderRadius: '7px',
                                            height: '38px',
                                            borderColor: '#555',
                                            fontFamily: 'RockfordSans-Light',
                                        },
                                    }}
                                ></TextField>
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={() => addGuest(party.id)}
                                    disabled={saving}
                                    fullWidth
                                    sx={{
                                        textTransform: 'none',
                                        boxShadow: 'none',
                                        padding: '8px',
                                        borderRadius: '8px',
                                        fontFamily: 'RockfordSans-Light',
                                        backgroundColor: saving
                                            ? '#888'
                                            : undefined,
                                    }}
                                >
                                    Add Guest +
                                </Button>
                            </div>
                        </Box>
                    ))}
                    <div
                        style={{
                            marginTop: '20px',
                            marginBottom: '20px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <TextField
                            required
                            fullWidth
                            value={partyName}
                            onChange={(event) =>
                                setPartyName(event.target.value)
                            }
                            placeholder="Party Name"
                            variant="outlined"
                            InputProps={{
                                style: {
                                    borderRadius: '7px',
                                    height: '38px',
                                    borderColor: '#555',
                                    fontFamily: 'RockfordSans-Light',
                                },
                            }}
                        ></TextField>
                        <Button
                            type="button"
                            variant="contained"
                            onClick={addParty}
                            disabled={saving}
                            fullWidth
                            sx={{
                                textTransform: 'none',
                                boxShadow: 'none',
                                padding: '8px',
                                borderRadius: '8px',
                                fontFamily: 'RockfordSans-Light',
                                backgroundColor: saving ? '#888' : undefined,
                            }}
                        >
                            Add Party +
                        </Button>
                    </div>
                    <hr style={{ width: '100%' }}></hr>
                    <Typography
                        sx={{
                            color: '#484848',
                            fontSize: { md: '20px', xs: '24px' },
                        }}
                    >
                        Add a new Password
                    </Typography>
                    <div
                        style={{
                            marginTop: '20px',
                            marginBottom: '20px',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <TextField
                            required
                            fullWidth
                            value={password}
                            onChange={(event) =>
                                setPassword(event.target.value)
                            }
                            placeholder="Password/密碼"
                            type="password"
                            variant="outlined"
                            InputProps={{
                                style: {
                                    borderRadius: '7px',
                                    height: '38px',
                                    borderColor: '#555',
                                    fontFamily: 'RockfordSans-Light',
                                },
                            }}
                        ></TextField>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={addPassword}
                            fullWidth
                            sx={{
                                textTransform: 'none',
                                boxShadow: 'none',
                                padding: '8px',
                                borderRadius: '8px',
                                fontFamily: 'RockfordSans-Light',
                            }}
                        >
                            Add Password
                        </Button>
                    </div>

                    <hr style={{ width: '100%' }}></hr>
                    <Button
                        type="button"
                        variant="contained"
                        onClick={signOut}
                        fullWidth
                        sx={{
                            textTransform: 'none',
                            boxShadow: 'none',
                            padding: '8px',
                            borderRadius: '8px',
                            fontFamily: 'RockfordSans-Light',
                            backgroundColor: '#c44',
                        }}
                    >
                        Logout/登出
                    </Button>

                    <Snackbar
                        open={open}
                        autoHideDuration={1000}
                        onClose={handleClose}
                    >
                        <Alert
                            onClose={handleClose}
                            severity="success"
                            sx={{ width: '100%' }}
                        >
                            Saved successfully! 保存成功！
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={errorOpen}
                        autoHideDuration={1000}
                        onClose={handleClose}
                    >
                        <Alert
                            onClose={handleClose}
                            severity="error"
                            sx={{ width: '100%' }}
                        >
                            Could not save. 不能保存。
                        </Alert>
                    </Snackbar>
                </Box>
            </div>
        </>
    );
}
