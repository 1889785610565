// client/src/App.js

import React, { useState, useEffect } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    Outlet,
    useLocation,
} from 'react-router-dom';

import Landing from './pages/Landing';
import Login from './pages/Login';
import Onboarding from './pages/Onboarding';
import Dashboard from './pages/Dashboard';
import Admin from './pages/Admin';
import List from './pages/List';
import { motion } from 'framer-motion';

import { api } from './api';
const PageLayout = ({ children }) => children;

const pageVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    },
};

const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.5,
};

const AnimationLayout = () => {
    const { pathname } = useLocation();
    return (
        <PageLayout>
            <motion.div
                key={pathname}
                initial="initial"
                animate="in"
                variants={pageVariants}
                transition={pageTransition}
            >
                <Outlet />
            </motion.div>
        </PageLayout>
    );
};

export default function App() {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [party, setParty] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        checkSignedIn();
    }, []);

    const checkSignedIn = async () => {
        const auth = await api.get(
            `/session?token=${localStorage.getItem('token')}`
        );

        if (auth.data.authorized) {
            setIsAdmin(auth.data.role === 'admin');
            setIsSignedIn(true);

            if (auth.data.party) {
                setParty(auth.data.party);
            }
        } else {
            setIsSignedIn(false);
        }
    };

    const logout = async () => {
        setParty(null)
        localStorage.removeItem('token');
        setIsSignedIn(false);
    }

    return (
        <Router>
            <Routes>
                <Route element={<AnimationLayout />}>
                    <Route
                        path="/onboarding"
                        element={
                            isSignedIn ? (
                                !!party ? (
                                    <Navigate to="/dashboard" />
                                ) : (
                                    <Onboarding checkSignedIn={checkSignedIn} />
                                )
                            ) : (
                                <Navigate to="/login" />
                            )
                        }
                    />
                    <Route path="/" element={<Landing />} />
                    <Route
                        path="/login"
                        element={
                            isSignedIn ? (
                                isAdmin ? (
                                    <Navigate to="/admin" />
                                ) : !!party ? (
                                    <Navigate to="/dashboard" />
                                ) : (
                                    <Navigate to="/onboarding" />
                                )
                            ) : (
                                <Login checkSignedIn={checkSignedIn} />
                            )
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            isSignedIn ? (
                                isAdmin ? (
                                    <Navigate to="/admin" />
                                ) : !!party ? (
                                    <Dashboard party={party} checkSignedIn={checkSignedIn} logout={logout} />
                                ) : (
                                    <Navigate to="/onboarding" />
                                )
                            ) : (
                                <Navigate to="/login" />
                            )
                        }
                    />
                    <Route
                        path="/admin"
                        element={
                            isSignedIn ? (
                                isAdmin ? (
                                    <Admin checkSignedIn={checkSignedIn} />
                                ) : (
                                    <Navigate to="/login" />
                                )
                            ) : (
                                <Navigate to="/login" />
                            )
                        }
                    />
                    <Route
                        path="/admin/list"
                        element={
                            isSignedIn ? (
                                isAdmin ? (
                                    <List checkSignedIn={checkSignedIn} />
                                ) : (
                                    <Navigate to="/login" />
                                )
                            ) : (
                                <Navigate to="/login" />
                            )
                        }
                    />
                </Route>
            </Routes>
        </Router>
    );
}
