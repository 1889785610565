import React, { useState } from 'react';
import {
    Button,
    TextField,
    Box,
    AppBar,
    Alert,
    Snackbar,
    Toolbar,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { api } from '../api';
import kt5 from '../kt5.jpeg';

export default function Onboarding({ checkSignedIn, token }) {
    const [isSigningOut, setIsSigningOut] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [guests, setGuests] = useState([]);
    const [guestName, setGuestName] = useState('');
    const [guest, setGuest] = useState(null);
    const [error, setError] = useState('');
    const [errorOpen, setErrorOpen] = useState(false);

    const navigate = useNavigate();

    const signOut = () => {
        localStorage.removeItem('token');
        checkSignedIn();
    };

    const handleClose = () => {
        setErrorOpen(false)
    }

    const select = async () => {
        const response = await api.post('/set-party', {
            token: localStorage.getItem('token'),
            guestName,
        });

        if (response.data.okay) {
            navigate('/dashboard');
        } else {
            setErrorOpen(true);
        }

        checkSignedIn();
    };

    return (
        <>
            <AppBar position="sticky">
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}
                >
                    <Link
                        to="/"
                        style={{ textDecoration: 'none', width: '100px' }}
                    >
                        <div
                            style={{
                                position: 'relative',
                                width: '100%',
                                textAlgin: 'center',
                                marginLeft: '5px',
                                marginTop: '5px',
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Hatton-Regular',
                                    fontSize: '48px',
                                    color: '#ccc',
                                    marginLeft: '15px',
                                }}
                            >
                                KT
                            </div>
                            <div
                                style={{
                                    fontFamily: 'BDScript-Regular',
                                    fontSize: '28px',
                                    color: '#eee',
                                    position: 'absolute',
                                    top: '8px',
                                    zIndex: 2,
                                    width: '100%',
                                }}
                            >
                                Kevin &amp; Thoa
                            </div>
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: '25px',
                                    backgroundColor: '#77b3cc',
                                    width: '100%',
                                    height: '14px',
                                }}
                            ></div>
                        </div>
                    </Link>
                </Toolbar>
            </AppBar>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#a9e5ff',
                    minHeight: '100vh',
                }}
            >
                <Box
                    class="item-single"
                    style={{
                        backgroundColor: '#a9e5ff',
                        display: 'flex',
                        justifyContent: 'center',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                    }}
                >
                    <Box class="polaroid-single" style={{ width: '250px' }}>
                        <img src={kt5} alt="kt5" />
                        <div class="caption">Heyyo</div>
                    </Box>
                </Box>
                <div
                    className="App"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        minHeight: '0',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '50px',
                            backgroundColor: 'white',
                            borderRadius: '20px',
                            boxShadow: 4,
                            minWidth: { sm: '400px', xs: '90vw' },
                        }}
                    >
                        <TextField
                            required
                            fullWidth
                            placeholder="Your Name/您的名字"
                            type="text"
                            variant="outlined"
                            value={guestName}
                            sx={{
                                borderRadius: '7px',
                                height: '38px',
                                borderColor: '#555',
                                fontFamily: 'RockfordSans-Light',
                            }}
                            InputProps={{
                                style: {
                                    borderRadius: '7px',
                                    height: '38px',
                                    borderColor: '#555',
                                    fontFamily: 'RockfordSans-Light',
                                    paddingTop: 0,
                                },
                            }}
                            onChange={(event) =>
                                setGuestName(event.target.value)
                            }
                            onKeyDown={event => {
                                if(event.key === 'Enter') {
                                    select()
                                }
                            }}
                        ></TextField>
                        <Button
                            type="button"
                            variant="contained"
                            onClick={select}
                            disabled={guestName.length === 0}
                            fullWidth
                            sx={{
                                textTransform: 'none',
                                boxShadow: 'none',
                                padding: '8px',
                                borderRadius: '8px',
                                fontFamily: 'RockfordSans-Light',
                                backgroundColor: isLoading ? '#888' : undefined,
                            }}
                        >
                            Confirm/確定
                        </Button>

                        <Button
                            type="button"
                            variant="contained"
                            onClick={signOut}
                            fullWidth
                            sx={{
                                textTransform: 'none',
                                boxShadow: 'none',
                                padding: '8px',
                                borderRadius: '8px',
                                fontFamily: 'RockfordSans-Light',
                                backgroundColor: isSigningOut ? '#888' : '#c44',
                            }}
                        >
                            Logout/登出
                        </Button>
                    </Box>
                </div>
            </div>

            <Snackbar
                open={errorOpen}
                autoHideDuration={1000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    Could not find your name. Please type your full name.
                </Alert>
            </Snackbar>
        </>
    );
}
