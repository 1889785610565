import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';

import logo from '../logo.svg';
import kt1 from '../kt1.jpeg';
import kt2 from '../kt2.jpg';
import kt3 from '../kt3.jpg';
import kt4 from '../kt4.jpeg';
import '../App.css';
import { Typography, Fade } from '@mui/material';
import { useEffect } from 'react';

export default function Home() {
    const [fade, setFade] = useState(false);

    useEffect(() => {
        setTimeout(() => setFade(true), 1300);
    });

    return (
        <div className="App">
            <header className="App-header">
                <div class="wrapper">
                    <div class="item">
                        <div class="polaroid">
                            <img src={kt1} alt="kt1" />
                            <div class="caption">Love u poo</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="polaroid">
                            <img src={kt2} alt="kt2" />
                            <div class="caption">forever in my heart</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="polaroid">
                            <img src={kt3} alt="kt3" />
                            <div class="caption">bum kevin</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="polaroid">
                            <img src={kt4} alt="kt4" />
                            <div class="caption">Vinsyencya</div>
                        </div>
                    </div>
                </div>
                <Link to="/login" style={{ textDecoration: 'none' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textTransform: 'none',
                            textDecoration: 'none',
                            marginTop: '-80px',
                        }}
                    >
                        <img src={logo} className="App-logo" alt="logo" />
                        <Fade in={fade} timeout={1000}>
                            <Button
                                type="button"
                                variant="contained"
                                component={Link}
                                to="/login"
                                fullWidth
                                sx={{
                                    textTransform: 'none',
                                    textDecoration: 'none',
                                    boxShadow: 'none',
                                    padding: '8px',
                                    borderRadius: '8px',
                                    fontFamily: 'RockfordSans-Light',
                                    marginTop: { sm: '-40px', xs: '0px' },
                                    width: { sm: '40vw', xs: '75vw' },
                                }}
                            >
                                Sign In/登入
                            </Button>
                        </Fade>
                    </div>
                </Link>
                <div style={{ position: 'absolute', bottom: '27vh' }}>
                </div>
                <div style={{ position: 'absolute', bottom: '2vh' }}>
                    <Typography
                        sx={{
                            fontFamily: 'Allura',
                            fontSize: { md: '48px', xs: '36px' },
                        }}
                    >
                        2nd December 2023
                    </Typography>
                    <Typography
                        sx={{
                            color: '#484848',
                            fontFamily: 'PlayfairDisplayBold',
                            fontSize: { md: '18px', xs: '16px' },
                            textTransform: 'uppercase',
                            fontWeight: 900,
                            marginBottom: '10px',
                        }}
                    >
                        Gunners Barracks
                    </Typography>
                    <Typography
                        sx={{
                            color: '#484848',
                            fontFamily: 'PlayfairDisplayBold',
                            fontSize: { md: '18px', xs: '16px' },
                            textTransform: 'uppercase',
                            fontWeight: 900,
                            marginBottom: '10px',
                        }}
                    >
                        Formal Attire
                    </Typography>
                    <Typography
                        sx={{
                            color: '#484848',
                            fontFamily: 'PlayfairDisplay',
                            fontSize: { md: '20px', xs: '17px' },
                        }}
                    >
                        <em>Wedding Ceremony</em> 5:30PM
                    </Typography>
                    <Typography
                        sx={{
                            color: '#484848',
                            fontFamily: 'PlayfairDisplay',
                            fontSize: { md: '20px', xs: '17px' },
                            marginBottom: '10px',
                        }}
                    >
                        <em>Reception</em> 6PM - 11PM
                    </Typography>
                    <Typography
                        sx={{
                            color: '#484848',
                            fontFamily: 'PlayfairDisplayBold',
                            fontSize: { md: '16px', xs: '14px' },
                            textTransform: 'uppercase',
                            fontWeight: 900,
                            marginBottom: '10px',
                        }}
                    >
                        End of, Suakin Drive, Mosman NSW 2088
                    </Typography>
                </div>
            </header>
        </div>
    );
}
